import React from "react";
import PropTypes from "prop-types";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

function createAppearance({ theme }) {
  return {
    paragraph: {
      className: `
        text-base
        mb-4
        break-word
      `,
    },
    heading1: {
      className: `
        text-5xl
        
        mb-4
        leading-tight
        font-bold
        font-display
      `,
      style: {
        color: theme?.title?.color,
      },
    },
    heading2: {
      className: `
        text-2xl
        
        mb-4
        leading-tight
        font-display
      `,
      style: {
        color: theme?.title?.color,
      },
    },
    heading3: {
      className: `
        text-xl
        
        mb-4
        leading-tight
        font-display
      `,
      style: {
        color: theme?.title?.color,
      },
    },
    heading4: {
      className: `
        text-xl
        
        mb-4
        leading-tight
        font-display
      `,
      style: {
        color: theme?.title?.color,
      },
    },
  };
}

function createOptions({ appearance }) {
  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 {...appearance.heading1}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 {...appearance.heading2}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 {...appearance.heading3}>{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 {...appearance.heading4}>{children}</h4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p {...appearance.paragraph}>{children}</p>
      ),
    },
  };
}

export default function RichText(props) {
  const { json } = props;
  const appearance = createAppearance(props);
  const options = createOptions({ appearance });
  return renderRichText(json, options);
}

RichText.propTypes = {
  theme: PropTypes.shape({
    title: PropTypes.shape({
      color: PropTypes.string.isRequired,
      fontFamily: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
